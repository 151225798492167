var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { attrs: { fluid: "" } }, [
    _c("div", {
      ref: "map-root",
      staticClass: "mb-2",
      staticStyle: {
        height: "75vh",
        border: "1px solid #ccc",
        background: "white",
      },
      style: {
        height: _vm.screenSize
          ? _vm.ifInsideDialog
            ? _vm.$vuetify.breakpoint.height - 90 + "px"
            : "25vh"
          : "75vh",
      },
      attrs: { id: "map" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }