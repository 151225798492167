var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-row", { staticClass: "mb-2", attrs: { "no-gutters": "" } }, [
        _c(
          "span",
          {
            staticClass:
              "font-weight-medium grey--text text--darken-4 text-subtitle-1",
          },
          [_vm._v(_vm._s(_vm.$t("associateTicketWithProject")))]
        ),
      ]),
      _c(
        "v-row",
        { staticClass: "mt-4 mb-6", attrs: { "no-gutters": "" } },
        [
          _c(
            "span",
            {
              staticClass:
                "mr-4 my-auto mt-1 font-weight-regular grey--text text--darken-2 text-subtitle-2",
            },
            [_vm._v(" " + _vm._s(_vm.$t("NO")) + " ")]
          ),
          _c("v-switch", {
            staticClass:
              "mt-0 info--text switch-track-inset-opacity switch-track-inset-height",
            attrs: {
              inset: "",
              "hide-details": "",
              color: "info",
              "background-color": "white",
            },
            scopedSlots: _vm._u([
              {
                key: "label",
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        staticClass:
                          "font-weight-regular grey--text text--darken-4 text-subtitle-2",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("YES")) + " ")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.associateTicketFlag,
              callback: function ($$v) {
                _vm.associateTicketFlag = $$v
              },
              expression: "associateTicketFlag",
            },
          }),
        ],
        1
      ),
      _vm.associateTicketFlag
        ? _c(
            "div",
            { staticClass: "mt-4 mb-8" },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-select", {
                    attrs: {
                      items: _vm.projectList,
                      "menu-props": { bottom: true, offsetY: true },
                      label: "Project Name",
                      outlined: "",
                      dense: "",
                      required: "",
                      "hide-details": "",
                      "item-text": "project_name",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.selectedProjectId,
                      callback: function ($$v) {
                        _vm.selectedProjectId = $$v
                      },
                      expression: "selectedProjectId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }