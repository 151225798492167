<template>
  <v-container fluid class="pa-0">
    <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="pt-2 pl-8">
      <span class="font-weight-medium grey--text text--darken-4 text-h6">{{
        $t("excavationDetails")
      }}</span>
    </v-row>
    <v-row v-if="$vuetify.breakpoint.smAndDown">
      <v-card
        width="100%"
        class="rounded-lg"
        elevation="0"
        style="border: 1px solid #e0e0e0; border-radius: 8%"
      >
        <MapView />
        <v-card-actions>
          <v-row justify="center">
            <span
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 mr-2 my-2"
              >{{ $store.state.Ticket.ticketData ? streetAddress : "" }}</span
            >
            <a class="primary--text my-2 body-2" @click="$emit('editLocation')">
              <img
                src="@/assets/images/edit_location.svg"
                role="img"
                alt="edit location Logo"
              />
              <span class="mb-auto">{{ $t("editLocation") }}</span></a
            >
          </v-row>
        </v-card-actions>
        <v-card-text>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.county ? '' : 'my-auto'"
            >
              {{ $t("county") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.county &&
                $store.state.Ticket.ticketData.county != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{
                $store.state.Ticket.ticketData
                  ? $store.state.Ticket.ticketData.county
                  : ""
              }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="county"
                outlined
                dense
                required
                hide-details
                placeholder="Enter County"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.place ? '' : 'my-auto'"
            >
              {{ $t("place") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.place &&
                $store.state.Ticket.ticketData.place != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{
                $store.state.Ticket.ticketData
                  ? $store.state.Ticket.ticketData.place
                  : ""
              }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="place"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Place"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="3"
              class="py-1"
              :class="$store.state.Ticket.ticketData.street ? '' : 'my-auto'"
            >
              {{ $t("street") }} :
            </v-col>
            <v-col
              v-if="
                $store.state.Ticket.ticketData.street &&
                $store.state.Ticket.ticketData.street != ''
              "
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{ $store.state.Ticket.ticketData ? streetAddress : "" }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="street"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Street"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :class="streetLocation.cross1 ? '' : 'my-auto'"
              cols="3"
              class="py-1"
            >
              {{ $t("cross1") }} :
            </v-col>
            <v-col
              v-if="streetLocation.cross1 && streetLocation.cross1 != ''"
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{ streetLocation.cross1 }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="cross1"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Cross 1"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :class="streetLocation.cross2 ? '' : 'my-auto'"
              cols="3"
              class="py-1"
            >
              {{ $t("cross2") }} :
            </v-col>
            <v-col
              v-if="streetLocation.cross2 && streetLocation.cross2 != ''"
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{ streetLocation.cross2 }}
            </v-col>
            <v-col
              v-else
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              <v-text-field
                v-model="cross2"
                outlined
                dense
                required
                hide-details
                placeholder="Enter Cross 2"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1"> {{ $t("location") }} : </v-col>
            <v-col
              class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
            >
              {{
                streetLocation.description ? streetLocation.description : "—"
              }}
            </v-col>
          </v-row>
        </v-card-text>
      </v-card></v-row
    >
    <v-row
      no-gutters
      :class="$vuetify.breakpoint.mdAndUp ? 'pl-8 pt-2' : 'mt-1 mx-5'"
    >
      <v-col>
        <v-row no-gutters class="mt-2">
          <span
            class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
            >{{ $t("811Center") }}</span
          >
        </v-row>
        <v-row
          no-gutters
          :class="$vuetify.breakpoint.mdAndUp ? 'pt-5 pb-2' : 'py-3'"
        >
          <v-col>
            <img
              class="pa-4"
              style="border: 1px solid #e0e0e0; border-radius: 8%"
              src="@/assets/images/VA811.svg"
              role="img"
              alt="VA 811 Center Logo"
            />
          </v-col>
          <v-col :class="$vuetify.breakpoint.mdAndUp ? 'mt-2' : 'mt-2 ml-10'">
            <v-row no-gutters>
              <span
                class="font-weight-normal grey--text text--darken-1 text-caption"
                >{{ $t("excavatorType") }}</span
              >
            </v-row>
            <v-row no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ companyExcavatorType }}</span
              >
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mb-2">
          <v-divider></v-divider>
        </v-row>

        <v-row no-gutters>
          <v-col class="mt-4 mb-10">
            <AssociateTicketExcvDetails
              v-if="checkPermission('associateTicketExcavationDetails')"
              ref="associateTicket"
            />
            <v-row class="mb-2" no-gutters>
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
                >{{ $t("tellAboutExcavation") }}</span
              >
            </v-row>
            <v-row no-gutters justify="center">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="work_date"
                    label="Excavation Start Date"
                    persistent-hint
                    outlined
                    dense
                    prepend-inner-icon="mdi-calendar"
                    v-bind="attrs"
                    :error-messages="dateFormattedErrors"
                    v-on="on"
                    @input="$v.work_date.$touch()"
                    @blur="$v.work_date.$touch()"
                    ><template v-slot:label>
                      <span class="red--text"> *</span>
                      {{ $t("excavationStartDate") }}
                    </template></v-text-field
                  >
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  :min="getNowDate"
                  :max="getEndDate"
                  :allowed-dates="allowedDates"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="scheduleTime"
                    :label="$t('time')"
                    format="ampm"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                    persistent-hint
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    :class="$vuetify.breakpoint.mdAndUp ? 'ml-3' : ''"
                    :error-messages="scheduleTimeError"
                    @input="$v.scheduleTime.$touch()"
                    @blur="$v.scheduleTime.$touch()"
                    readonly
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="time"
                  :menu-props="{ bottom: true, offsetY: true }"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(time)">
                    OK
                  </v-btn>
                </v-time-picker>
              </v-menu>
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="contact"
                label="Site Contact Name"
                outlined
                dense
                required
                :error-messages="contactErrors"
                @input="$v.contact.$touch()"
                @blur="$v.contact.$touch()"
                ><template v-slot:label>
                  <span class="red--text"> *</span> {{ $t("siteContactName") }}
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="contact_phone"
                label="Site Contact Phone"
                outlined
                dense
                required
                :error-messages="contactPhoneErrors"
                @input="$v.contact_phone.$touch()"
                @blur="$v.contact_phone.$touch()"
                ><template v-slot:label>
                  <span class="red--text"> *</span> {{ $t("siteContactPhone") }}
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="done_for"
                label="Work Done For"
                outlined
                dense
                required
                :error-messages="workDoneForErrors"
                @input="$v.done_for.$touch()"
                @blur="$v.done_for.$touch()"
                ><template v-slot:label>
                  <span class="red--text"> *</span> {{ $t("workDoneFor") }}
                </template></v-text-field
              >
            </v-row>
            <v-row no-gutters>
              <v-text-field
                v-model="subdivision"
                label="Sub-Division"
                outlined
                dense
                :error-messages="subdivisionErrors"
                @input="$v.subdivision.$touch()"
                @blur="$v.subdivision.$touch()"
              ></v-text-field>
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
              >
                {{ $t("excavationAreaOnProperty") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("excavationAreaOnPropertyToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <v-row no-gutters>
              <v-select
                v-model="location"
                :class="
                  location && $vuetify.breakpoint.smAndDown
                    ? 'select-container'
                    : ''
                "
                :items="selectExcavationAreaOnProperty"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Excavation Area on Property"
                outlined
                dense
                required
                item-value="text"
                :error-messages="excavationAreaErrors"
                @input="$v.location.$touch()"
                @blur="$v.location.$touch()"
                ><template v-slot:label>
                  <span class="red--text"> *</span>
                  {{ $t("excavationAreaOnProperty") }}
                </template></v-select
              >
            </v-row>
            <v-row no-gutters class="mt-4 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("typeOfWork") }}</span
              ><v-tooltip
                :right="!$vuetify.breakpoint.smAndDown"
                :bottom="$vuetify.breakpoint.smAndDown"
                max-width="400"
                class="pa-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="ml-4"
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("typeOfWorkToolTip") }}</span>
              </v-tooltip>
            </v-row>
            <div v-if="typeOfWorkTextBox">
              <v-row no-gutters>
                <v-text-field
                  v-model="work_type"
                  label="Enter Type of Work"
                  outlined
                  dense
                  required
                  :hide-details="typeOFWorkErrors.length == 0"
                  :error-messages="typeOFWorkErrors"
                  @input="$v.work_type.$touch()"
                  @blur="$v.work_type.$touch()"
                  ><template v-slot:label>
                    <span class="red--text"> *</span> Enter
                    {{ $t("typeOfWork") }}
                  </template></v-text-field
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text my-2 body-2"
                  @click="disableTypeOfWorkTextBox()"
                  >{{ $t("searchFromList") }}</a
                >
              </v-row>
            </div>
            <div v-else>
              <v-row no-gutters>
                <v-select
                  v-model="work_type"
                  :items="workTypeOptions"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Type of Work"
                  outlined
                  dense
                  required
                  item-value="text"
                  :error-messages="typeOFWorkErrors"
                  :hide-details="typeOFWorkErrors.length == 0"
                  @change="emptyWorkType"
                  @input="$v.work_type.$touch()"
                  @blur="$v.work_type.$touch()"
                  ><template v-slot:label>
                    <span class="red--text"> *</span> {{ $t("typeOfWork") }}
                  </template></v-select
                >
              </v-row>
              <v-row no-gutters>
                <a
                  class="primary--text my-2 body-2"
                  @click="enableTypeOfWorkTextBox()"
                  >{{ $t("dontSeeTypeOfWork") }}</a
                >
              </v-row>
            </div>
            <v-row no-gutters class="mt-6">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("locatorInstructions") }}
                <v-tooltip
                  :right="!$vuetify.breakpoint.smAndDown"
                  :bottom="$vuetify.breakpoint.smAndDown"
                  max-width="400"
                  class="pa-0"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="ml-4"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{ $t("locatorInstructionTooltip") }}</span>
                </v-tooltip></span
              >
            </v-row>
            <div>
              <v-row no-gutters>
                <v-checkbox
                  v-model="remarks"
                  class="mt-3"
                  hide-details
                  value="Please do not mark on driveway, sidewalk and/or patio"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("drivewayLabel") }}
                    </div>
                  </template>
                </v-checkbox>
              </v-row>
              <v-row no-gutters class="mb-10">
                <v-checkbox
                  v-model="remarks"
                  hide-details
                  value="Please use paint and flags if available"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("usePaintLabel") }}
                    </div>
                  </template></v-checkbox
                >
              </v-row>
            </div>
            <v-row no-gutters class="mt-6 mb-0">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("additionalInformation") }}
                <span
                  class="font-weight-normal grey--text text--darken-1 text-caption"
                >
                  {{ $t("checkAllApply") }}</span
                ></span
              >
            </v-row>
            <div class="mb-10">
              <v-row no-gutters>
                <v-switch
                  v-model="white_paint"
                  inset
                  color="info"
                  background-color="white"
                  hide-details
                  class="mt-3"
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("vupsPremarksLabel") }}
                    </div>
                  </template>
                </v-switch>
              </v-row>
              <v-row no-gutters>
                <v-switch
                  v-model="boring"
                  inset
                  color="info"
                  background-color="white"
                  hide-details
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("vupsBoringLabel") }}
                    </div>
                  </template></v-switch
                >
              </v-row>
              <v-row no-gutters>
                <v-switch
                  v-model="blasting"
                  inset
                  color="info"
                  background-color="white"
                  hide-details
                >
                  <template v-slot:label>
                    <div
                      class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
                    >
                      {{ $t("vupsBlastingLabel") }}
                    </div>
                  </template>
                </v-switch>
              </v-row>
            </div>
            <v-row no-gutters class="mt-2 mb-2">
              <span
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2"
                >{{ $t("additionalComments") }}</span
              >
            </v-row>
            <v-row
              no-gutters
              :class="$vuetify.breakpoint.mdAndUp ? 'mb-10' : 'mb-16'"
            >
              <v-textarea
                v-model="comments"
                label="Comments"
                :error-messages="commentsErrors"
                rows="2"
                counter="120"
                outlined
                dense
                required
                @input="$v.comments.$touch()"
                @blur="$v.comments.$touch()"
              ></v-textarea>
            </v-row>
            <v-row v-if="$vuetify.breakpoint.mdAndUp" no-gutters class="mb-16">
              <v-btn
                color="primary"
                class="text-none rounded-lg elevation-0"
                outlined
                width="90"
                @click="backButton()"
              >
                {{ $t("back") }}
              </v-btn>

              <v-btn
                class="ml-6 primary text-none rounded-lg elevation-0"
                width="90"
                depressed
                :disabled="$v.$invalid"
                @click="continueButton"
              >
                {{ $t("continue") }}
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <v-card
          width="90%"
          class="rounded-lg mx-4"
          elevation="0"
          style="border: 1px solid #e0e0e0; border-radius: 8%"
        >
          <v-card-title class="pt-2">
            <v-row>
              <v-col class="pt-2">
                <span
                  class="font-weight-medium grey--text text--darken-4 text-subtitle-1 mr-2 my-2"
                  >{{
                    $store.state.Ticket.ticketData ? streetAddress : ""
                  }}</span
                >
              </v-col>
              <v-col class="d-flex justify-end pt-2">
                <a
                  class="primary--text my-2 body-2"
                  @click="$emit('editLocation')"
                >
                  <img
                    src="@/assets/images/edit_location.svg"
                    role="img"
                    alt="edit location Logo"
                  />
                  <span class="mb-auto">{{ $t("editLocation") }}</span></a
                ></v-col
              >
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.county &&
                  $store.state.Ticket.ticketData.county != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("county") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.county &&
                  $store.state.Ticket.ticketData.county != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{
                  $store.state.Ticket.ticketData
                    ? $store.state.Ticket.ticketData.county
                    : "—"
                }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="county"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter County"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.place &&
                  $store.state.Ticket.ticketData.place != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("place") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.place &&
                  $store.state.Ticket.ticketData.place != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{
                  $store.state.Ticket.ticketData
                    ? $store.state.Ticket.ticketData.place
                    : "—"
                }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="place"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Place"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="
                  $store.state.Ticket.ticketData.street &&
                  $store.state.Ticket.ticketData.street != ''
                    ? ''
                    : 'my-auto'
                "
              >
                {{ $t("street") }} :
              </v-col>
              <v-col
                v-if="
                  $store.state.Ticket.ticketData.street &&
                  $store.state.Ticket.ticketData.street != ''
                "
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{ $store.state.Ticket.ticketData ? streetAddress : "—" }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="street"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Street"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="streetLocation.cross1 ? '' : 'my-auto'"
              >
                {{ $t("cross1") }} :
              </v-col>
              <v-col
                v-if="streetLocation.cross1 && streetLocation.cross1 !== ''"
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{ streetLocation.cross1 }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="cross1"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Cross 1"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="2"
                class="py-1"
                :class="streetLocation.cross2 ? '' : 'my-auto'"
              >
                {{ $t("cross2") }} :
              </v-col>
              <v-col
                v-if="streetLocation.cross2 && streetLocation.cross2 !== ''"
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{ streetLocation.cross2 }}
              </v-col>
              <v-col
                v-else
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                <v-text-field
                  v-model="cross2"
                  outlined
                  dense
                  required
                  hide-details
                  placeholder="Enter Cross 2"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2" class="py-1"> {{ $t("location") }} : </v-col>
              <v-col
                class="font-weight-medium grey--text text--darken-4 text-subtitle-2 py-1"
              >
                {{
                  streetLocation.description ? streetLocation.description : "—"
                }}
              </v-col>
            </v-row>
          </v-card-text>
          <MapView :is-edit-view="isEditView" /> </v-card
      ></v-col>

      <v-bottom-navigation
        v-if="$vuetify.breakpoint.smAndDown"
        fixed
        horizontal
        height="70"
        class="px-2 py-4"
        ><v-row no-gutters justify="space-between">
          <v-col cols="1" class="pa-0">
            <v-btn
              outlined
              color="primary"
              class="rounded-lg"
              height="50"
              @click="backButton"
            >
              <v-icon color="primary">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9" class="pa-0">
            <v-btn
              color="primary"
              class="text-none rounded-lg font-weight-regular text-h6 white--text"
              height="50"
              block
              :disabled="$v.$invalid"
              @click="continueButton"
              ><span class="white--text">{{ $t("continue") }}</span>
            </v-btn>
          </v-col>
        </v-row></v-bottom-navigation
      >
    </v-row>
  </v-container>
</template>
<script>
import excavationAreaJSON from "@/static/excavationAreaOnProperty.json";
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { required, maxLength } from "vuelidate/lib/validators";
import MapView from "../../MapView.vue";
import moment from "moment";
import UserDataMixin from "@/mixins/UserData";
import AssociateTicketExcvDetails from "../../AssociateTicketExcvDetails.vue";

const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
const dateText = helpers.regex(
  "date",
  /(0\d{1}|1[0-2])\/([0-2]\d{1}|3[0-1])\/(19|20)(\d{2})/
);

export default {
  components: {
    MapView: () => import("../../MapView.vue"),
    AssociateTicketExcvDetails: () =>
      import("../../AssociateTicketExcvDetails.vue"),
  },
  mixins: [validationMixin, UserDataMixin],
  validations() {
    return {
      work_date: { required, dateText },
      contact: { required, maxLength: maxLength(30) },
      contact_phone: { required, phone },
      work_type: { required },
      done_for: { required, maxLength: maxLength(40) },
      subdivision: { maxLength: maxLength(40) },
      location: { required },
      comments: { maxLength: maxLength(120) },
      scheduleTime: { required },
    };
  },
  props: {
    mapView: {
      type: String,
      default: () => "",
    },
    isEditView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      center: this.$store.state.Ticket.ticketData.center,
      picker: new Date(),
      menu1: false,
      typeOfWorkTextBox: false,
      mapViewHtml: "",
      startDate: "",
      selectExcavationAreaOnProperty:
        excavationAreaJSON.vupsExcavationAreaOnProperty,
      workTypeOptions: [],
      date: "",
      work_date: "",
      contact: "",
      contact_phone: "",
      work_type: "",
      done_for: "",
      remarks: [],
      subdivision: "",
      boring: false,
      blasting: false,
      white_paint: false,
      location: "",
      comments: "",
      cross1: "",
      cross2: "",
      place: "",
      street: "",
      county: "",
      time: null,
      scheduleTime: null,
      menu2: false,
    };
  },
  computed: {
    streetLocation() {
      return this.$store.state.Ticket.ticketData.streetLocation;
    },
    getNowDate() {
      var beginDate = new Date(this.picker.setDate(this.picker.getDate() + 3));
      while (!this.disableWeekends(beginDate)) {
        beginDate.setDate(beginDate.getDate() + 1);
      }
      this.setStartDate(beginDate);
      return beginDate.toISOString().slice(0, 10);
    },
    getEndDate() {
      var endDate = "";
      let count = 1,
        noOfDaysToAdd = 10;
      if (this.startDate) {
        let beginDate = this.startDate;
        let tempDate = "";
        while (count < noOfDaysToAdd) {
          tempDate = new Date(beginDate.setDate(beginDate.getDate() + 1));
          if (tempDate.getDay() != 0 && tempDate.getDay() != 6) {
            count++;
          }
        }
        endDate = new Date(tempDate.setDate(tempDate.getDate()));
      }
      return endDate.toISOString().slice(0, 10);
    },
    companyExcavatorType() {
      let excavatorType = "";
      if (
        this.$store.state.Company.company.centers &&
        this.$store.state.Company.company.centers.length > 0
      ) {
        excavatorType = this.$store.state.Company.company.centers.filter(
          (item) => {
            return item.abbrev === this.center;
          }
        )[0].excavator_type;
      }
      return excavatorType;
    },
    contactErrors() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.required &&
        errors.push(this.$t("contactNameRequiredError"));
      !this.$v.contact.maxLength && errors.push(this.$t("unccNameLengthError"));
      return errors;
    },
    dateFormattedErrors() {
      const errors = [];
      if (!this.$v.work_date.$dirty) return errors;
      !this.$v.work_date.required &&
        errors.push(this.$t("dateFormattedRequiredError"));
      !this.$v.work_date.dateText &&
        errors.push(this.$t("dateFormattedInvalidError"));

      return errors;
    },
    contactPhoneErrors() {
      const errors = [];
      if (!this.$v.contact_phone.$dirty) return errors;
      !this.$v.contact_phone.phone &&
        errors.push(this.$t("contactPhoneValidError"));
      !this.$v.contact_phone.required &&
        errors.push(this.$t("contactPhoneRequiredError"));
      return errors;
    },
    workDoneForErrors() {
      const errors = [];
      if (!this.$v.done_for.$dirty) return errors;
      !this.$v.done_for.required &&
        errors.push(this.$t("workDoneForRequiredError"));
      !this.$v.done_for.maxLength && errors.push(this.$t("vupsWorkDoneLabel"));
      return errors;
    },
    subdivisionErrors() {
      const errors = [];
      if (!this.$v.subdivision.$dirty) return errors;
      !this.$v.subdivision.maxLength &&
        errors.push(this.$t("subdivisionLengthError"));
      return errors;
    },
    commentsErrors() {
      const errors = [];
      if (!this.$v.comments.$dirty) return errors;
      !this.$v.comments.maxLength &&
        errors.push(this.$t("commentsLengthError"));
      return errors;
    },
    excavationAreaErrors() {
      const errors = [];
      if (!this.$v.location.$dirty) return errors;
      !this.$v.location.required &&
        errors.push(this.$t("excavationAreaRequiredError"));
      return errors;
    },
    typeOFWorkErrors() {
      const errors = [];
      if (!this.$v.work_type.$dirty) return errors;
      !this.$v.work_type.required &&
        errors.push(this.$t("typeOFWorkRequiredError"));
      return errors;
    },
    ticketData() {
      return this.$store.state.Ticket.ticketData;
    },
    scheduleTimeError() {
      const errors = [];
      if (!this.$v.scheduleTime.$dirty) return errors;
      !this.$v.scheduleTime.required &&
        errors.push(this.$t("scheduledTimeError"));
      return errors;
    },
    streetAddress() {
      const streetFromAddress =
        this.$store.state.Ticket.ticketData?.st_from_address;
      const street = this.$store.state.Ticket.ticketData?.street;
      return (streetFromAddress ? streetFromAddress + " " : "") + street;
    },
  },
  watch: {
    date(val) {
      this.work_date = this.formatDate(this.date);
    },
    time(val) {
      this.scheduleTime = this.time;
      console.log(this.time);
    },
  },
  async created() {
    if (this.$store.state.Ticket.excavationDetails.workTypeOptions)
      this.workTypeOptions =
        this.$store.state.Ticket.excavationDetails.workTypeOptions;
    if (
      this.ticketData &&
      this.ticketData.center &&
      !this.$store.state.Ticket.excavationDetails.workTypeOptions
    ) {
      await this.getTypeOfWork();
    }
    if (Object.keys(this.$store.state.Ticket.excavationDetails).length != 0) {
      this.date = moment(
        this.$store.state.Ticket.excavationDetails.work_date
      ).format("YYYY-MM-DD");
      this.work_date = moment(
        this.$store.state.Ticket.excavationDetails.work_date
      ).format("DD/MMM/YYYY");
      this.contact = this.$store.state.Ticket.excavationDetails.contact;
      this.contact_phone =
        this.$store.state.Ticket.excavationDetails.contact_phone;
      this.time = await this.getTimeFromWorkDate();
      this.work_type = this.$store.state.Ticket.excavationDetails.work_type;
      this.done_for = this.$store.state.Ticket.excavationDetails.done_for;
      this.subdivision = this.$store.state.Ticket.excavationDetails.subdivision;
      this.location = this.$store.state.Ticket.excavationDetails.location;
      this.remarks = this.$store.state.Ticket.excavationDetails.remarks;
      this.boring = this.$store.state.Ticket.excavationDetails.boring;
      this.white_paint = this.$store.state.Ticket.excavationDetails.white_paint;
      this.blasting = this.$store.state.Ticket.excavationDetails.blasting;
      this.comments = this.$store.state.Ticket.excavationDetails.comments;
      this.typeOfWorkTextBox =
        this.$store.state.Ticket.excavationDetails.typeOfWorkTextBox;
      this.$nextTick(() => {
        if (this.$store.state.Ticket.excavationDetails.associateProject) {
          this.$refs.associateTicket.selectedProjectId =
            this.$store.state.Ticket.excavationDetails.associateProject;
          if (
            this.$refs.associateTicket &&
            this.$refs.associateTicket.selectedProjectId &&
            Object.keys(this.$refs.associateTicket.selectedProjectId).length !=
              0
          )
            this.$refs.associateTicket.associateTicketFlag = true;
          else this.$refs.associateTicket.associateTicketFlag = false;
        }
      });
    }
  },
  methods: {
    async continueButton() {
      this.work_date = new Date(this.date).toISOString();
      let dateTime = moment(this.date, "YYYY-MM-DD").format("YYYY-MM-DD");
      dateTime += `T${this.time}:00.000Z`;
      this.scheduleTime = dateTime;
      let excavationDetails = {};
      excavationDetails = {
        work_date: this.scheduleTime,
        contact: this.contact,
        contact_phone: this.contact_phone,
        work_type: this.work_type,
        done_for: this.done_for,
        subdivision: this.subdivision,
        location: this.location,
        remarks: this.remarks,
        boring: this.boring,
        comments: this.comments,
        white_paint: this.white_paint,
        blasting: this.blasting,
        typeOfWorkTextBox: this.typeOfWorkTextBox,
        workTypeOptions: this.workTypeOptions,
      };
      if (
        this.$refs &&
        this.$refs.associateTicket &&
        this.$refs.associateTicket.selectedProjectId
      ) {
        excavationDetails.associateProject =
          this.$refs.associateTicket.selectedProjectId;
      }
      if (!this.$store.state.Ticket.ticketData.streetLocation.cross1)
        this.$store.state.Ticket.ticketData.streetLocation.cross1 = this.cross1;

      if (!this.$store.state.Ticket.ticketData.streetLocation.cross2)
        this.$store.state.Ticket.ticketData.streetLocation.cross2 = this.cross2;

      if (
        !this.$store.state.Ticket.ticketData.street &&
        this.$store.state.Ticket.ticketData.street != ""
      )
        this.$store.state.Ticket.ticketData.street = this.street;

      if (
        !this.$store.state.Ticket.ticketData.county &&
        this.$store.state.Ticket.ticketData.county != ""
      )
        this.$store.state.Ticket.ticketData.county = this.county;

      if (
        !this.$store.state.Ticket.ticketData.place &&
        this.$store.state.Ticket.ticketData.place != ""
      )
        this.$store.state.Ticket.ticketData.place = this.place;
      this.$store.commit("setExcavationDetails", excavationDetails);
      this.$emit("excavationDetailsCompleted");
    },
    async getTypeOfWork() {
      const centerObject = {};
      centerObject["center"] = this.ticketData.center;
      const workTypeStatus = await this.$store.dispatch(
        "getTypeOfWork",
        centerObject
      );
      let workTypeArray = [];
      if (workTypeStatus.status !== "error") {
        workTypeStatus.map((ele) => {
          let tempStr = ele.description.replace(
            /(\w)(\w*)/g,
            function (g0, g1, g2) {
              return g1.toUpperCase() + g2.toLowerCase();
            }
          );
          workTypeArray.push(tempStr);
        });
        workTypeArray.push("Other");
        this.workTypeOptions = workTypeArray;
      }
    },
    backButton() {
      this.$emit("excavationDetailsBack");
    },
    setStartDate(tempDate) {
      this.startDate = tempDate;
    },
    allowedDates(date) {
      var dayOfWeek = moment(date).day();
      return !(dayOfWeek == 0 || dayOfWeek == 6);
    },
    disableWeekends(date) {
      const day = new Date(date).getDay();
      return !(day === 0 || day === 6);
    },
    enableTypeOfWorkTextBox() {
      this.typeOfWorkTextBox = true;
      this.work_type = "";
      this.$v.work_type.$reset();
    },
    disableTypeOfWorkTextBox() {
      this.typeOfWorkTextBox = false;
      this.work_type = "";
      this.$v.work_type.$reset();
    },
    emptyWorkType() {
      if (this.work_type === "Other") {
        this.typeOfWorkTextBox = true;
        this.work_type = "";
        this.$nextTick(() => {
          this.$v.work_type.$reset();
        });
      }
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    async getTimeFromWorkDate() {
      const workDate = await this.$store.state.Ticket.excavationDetails
        .work_date;
      const timestamp = new Date(workDate);
      const hours = timestamp.getUTCHours().toString().padStart(2, "0"); // Ensure 2-digit format
      const minutes = timestamp.getUTCMinutes().toString().padStart(2, "0"); // Ensure 2-digit format
      const formattedTime = `${hours}:${minutes}`;

      return formattedTime;
    },
  },
};
</script>
<style lang="scss">
.select-container {
  .v-select__selections input {
    display: none;
  }
  .v-select__selections {
    display: grid !important;
  }
}
</style>
