<template>
  <v-container fluid>
    <div
      id="map"
      ref="map-root"
      style="height: 75vh; border: 1px solid #ccc; background: white"
      :style="{
        height: screenSize
          ? ifInsideDialog
            ? $vuetify.breakpoint.height - 90 + 'px'
            : '25vh'
          : '75vh',
      }"
      class="mb-2"
    />
  </v-container>
</template>

<script>
import { Style, Stroke, Fill, Text, Circle, RegularShape } from "ol/style";
import "ol/ol.css";
import { Map, View, Overlay, Feature, PluggableMap } from "ol";
import * as coordinate from "ol/coordinate";
import * as control from "ol/control";
import * as events from "ol/events";
import * as extent from "ol/extent";
import * as format from "ol/format";
import * as geom from "ol/geom";
import * as interaction from "ol/interaction";
import * as layer from "ol/layer";
import * as proj from "ol/proj";
import * as render from "ol/render";
import * as reproj from "ol/reproj";
import * as source from "ol/source";
import * as sphere from "ol/sphere";
import * as style from "ol/style";
import * as tilegrid from "ol/tilegrid";
import * as webgl from "ol/webgl";
import * as turf from "@turf/turf";

import "@/components/Map/QMOL.css";
import Compass from "@/assets/compass-rose.png";
import Logo from "@/components/Map/QMOL_Logo.png";
import QMOL_Data from "@/components/Map/QMOL_Data.js";
import { quickMap } from "@/components/Map/QMOL.js";
export default {
  props: {
    ifInsideDialog: {
      type: Boolean,
      default: () => false,
    },
    mapView: {
      type: String,
      default: () => "",
    },
    mapSize: {
      type: Boolean,
      default: () => false,
    },
    isEditView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bufferArea: {
        JULIE: 150,
        OUPS: 200,
        UTAH: 150,
        NCOCC: 150,
        UNCC: 150,
        VUPS: 150,
        USAS: 150,
      },
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.smAndDown || this.mapSize;
    },
  },
  mounted() {
    this.qm = new quickMap({
      ol: {
        View,
        Map,
        Overlay,
        Feature,
        PluggableMap,
        control,
        coordinate,
        events,
        extent,
        format,
        geom,
        interaction,
        layer,
        proj,
        render,
        reproj,
        source,
        sphere,
        style,
        tilegrid,
        webgl,
      },
      turf: turf,
      target: "map",
      compass: Compass,
      logo: Logo,
      extraData: QMOL_Data.QMOL_Data,
    });
    this.qm.showHelp = false;
    this.qm.showIntersection = false;
    this.qm.showToggleControls = false;
    this.qm.showBlock = false;
    this.qm.showFlag = false;
    this.qm.showDraw = false;
    this.qm.showEdit = false;
    this.qm.showDrawPoint = false;
    this.qm.showDrawLine = false;
    this.qm.showDrawPolygon = false;
    this.qm.showDelete = false;
    this.qm.showWeather = false;
    this.qm.showMeasure = false;
    this.qm.showBullseye = false;
    this.qm.showIdentify = false;
    this.qm.showClear = false;
    this.qm.showMeasureDirection = true;
    this.qm.showMeasureLengthFeet = true;
    this.qm.showMeasureLengthYards = false;
    this.qm.showMeasureLengthMiles = true;
    this.qm.showMeasureLengthMeters = false;
    this.qm.showMeasureLengthKilometers = false;
    this.qm.showMeasureAreaSqFeet = true;
    this.qm.showMeasureAreaSqYards = false;
    this.qm.showMeasureAreaAcres = true;
    this.qm.showMeasureAreaSqMiles = false;
    this.qm.showMeasureAreaSqMeters = false;
    this.qm.showMeasureAreaSqKilometers = false;
    let newGeojson = this.$store.state.Ticket.geoJson;
    let parcelExactInPlace =
      this.$store.state.Ticket.ticketData.parcelExactInPlace;
    if (this.isEditView) {
      this.qm.addToEdit(newGeojson);
      this.qm.zoomToEdit();
      return;
    }
    this.qm.addToShow(newGeojson);
    this.qm.zoomToShow();
    // let bufferdata;
    // let bufferFeature;
    // if (newGeojson && newGeojson.features && newGeojson.features.length > 1) {
    //   let bufferArea =
    //     newGeojson.features[parcelExactInPlace].geometry.type === "Point"
    //       ? this.bufferArea[this.$store.state.Ticket.ticketData.center]
    //       : 100;
    //   bufferdata = this.qm.getFeatureData(newGeojson)[parcelExactInPlace];
    //   bufferFeature = this.qm.bufferFeature(bufferdata, bufferArea);
    // } else {
    //   bufferdata = this.qm.getFeatureData(newGeojson)[0];
    //   bufferFeature = this.qm.bufferFeature(
    //     bufferdata,
    //     this.bufferArea[this.$store.state.Ticket.ticketData.center]
    //   );
    // }
    // bufferFeature.setStyle(
    //   new Style({
    //     stroke: new Stroke({
    //       color: [15, 10, 255, 1],
    //       width: 3,
    //     }),
    //     fill: new Fill({ color: [15, 10, 255, 0.1] }),
    //   })
    // );
    // this.qm.show.getSource().addFeature(bufferFeature);
    // document.addEventListener("qmolSingleClick", (e) => {
    //   this.singleClick(e);
    // });
  },
};
</script>

<style lang="scss" scoped></style>
