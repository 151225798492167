<template>
  <div>
    <v-row class="mb-2" no-gutters>
      <span
        class="font-weight-medium grey--text text--darken-4 text-subtitle-1"
        >{{ $t("associateTicketWithProject") }}</span
      >
    </v-row>
    <v-row no-gutters class="mt-4 mb-6">
      <span
        class="mr-4 my-auto mt-1 font-weight-regular grey--text text--darken-2 text-subtitle-2"
      >
        {{ $t("NO") }}
      </span>
      <v-switch
        v-model="associateTicketFlag"
        inset
        hide-details
        color="info"
        class="mt-0 info--text switch-track-inset-opacity switch-track-inset-height"
        background-color="white"
      >
        <template v-slot:label>
          <div
            class="font-weight-regular grey--text text--darken-4 text-subtitle-2"
          >
            {{ $t("YES") }}
          </div>
        </template>
      </v-switch>
    </v-row>
    <div v-if="associateTicketFlag" class="mt-4 mb-8">
      <v-row no-gutters>
        <v-select
          v-model="selectedProjectId"
          :items="projectList"
          :menu-props="{ bottom: true, offsetY: true }"
          label="Project Name"
          outlined
          dense
          required
          hide-details
          item-text="project_name"
          return-object
        >
        </v-select>
      </v-row>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      associateTicketFlag: false,
      selectedProjectId: {},
    };
  },
  computed: {
    projectList() {
      return this.$store.state.Project.project;
    },
  },
  watch: {
    associateTicketFlag() {
      if (this.associateTicketFlag === false) this.selectedProjectId = "";
    },
  },
};
</script>
